export class RouteCollection {
  public static readonly ROOT = '';

  public static readonly HOME = '/home';

  public static readonly LOGIN = '/login';
  public static readonly LOGOUT = '/logout';

  public static readonly PRODUCT = '/crawling/product';
  public static readonly CATEGORY = '/crawling/category';

  public static readonly LINK = '/crawling/link';
  public static readonly LINK_TYPE = '/crawling/link-type';
  public static readonly LINK_HISTORY = '/crawling/link-history';
}
