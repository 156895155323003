// Angulars
import { Component, OnInit } from '@angular/core';

// Libraries
import { Store } from '@ngxs/store';
import { timer } from 'rxjs';
import * as S from 'string';

// Helpers
import { UtilHelper } from '../helpers/util.helper';

// Services
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';

// Components
import { AppComponent } from '../app.component';

// States
import { AuthState } from '../modules/auth/auth.state';

// Configs
import { RouteCollection } from '../config/route.collection';

@Component({
  templateUrl: './admin-layout.component.html',
})
export class AdminLayoutComponent implements OnInit {
  // Menu
  menues: any[] = [];

  // Permissions
  permissions: any[] = [];

  constructor(
    protected app: AppComponent,
    private store: Store,
    protected translateService: TranslateService,
    protected util: UtilHelper,
    protected auth: AuthService,
  ) {}

  ngOnInit(): void {
    // Inisialisasi akses
    this.loadPermission();

    // Inisialisasi main menu
    this.loadMenu('main');
    this.translateMenu(this.menues['main']);
    this.checkMenuPermission(this.menues['main']);
    this.hideUnallowedMenu(this.menues['main']);

    // Inisialisasi profile menu
    this.loadMenu('profile');
    this.translateMenu(this.menues['profile']);
  }

  /**
   * Fungsi untuk inisialisasi daftar menu
   * @param type
   */
  loadMenu(type) {
    // Menubar
    if (type == 'main') {
      this.menues[type] = [
        {
          id: 'product',
          items: [
            { id: 'category', icon: { name: 'globe', size: 13 }, permission: 'view-product', routerLink: [RouteCollection.CATEGORY], linkedPage: 'category' },
            { separator: true },
            { id: 'product', icon: { name: 'book', size: 13 }, permission: 'view-product', routerLink: [RouteCollection.PRODUCT], linkedPage: 'product' },
          ],
        },
        {
          id: 'link',
          items: [
            { id: 'link_type', icon: { name: 'globe', size: 13 }, permission: 'view-product', routerLink: [RouteCollection.LINK_TYPE], linkedPage: 'link_type' },
            { separator: true },
            { id: 'link', icon: { name: 'globe', size: 13 }, permission: 'view-product', routerLink: [RouteCollection.LINK], linkedPage: 'link' },
          ]
        },
       
        { id: 'link_history', icon: { name: 'globe', size: 13 }, permission: 'view-product', routerLink: [RouteCollection.LINK_HISTORY], linkedPage: 'link_history' }
      ];
    }

    // Profile
    else if (type == 'profile') {
      this.menues[type] = [
        {
          id: 'logout',
          command: event => {
            this.app.logout();
          },
        },
      ];
    }
  }

  /**
   * Fungsi untuk menerjemahkan daftar menu
   * @param menues
   */
  translateMenu(menues: any[]) {
    // Terjemahkan menu
    for (let menu of menues) {
      // Abaikan jika separator
      if (menu.separator) {
        continue;
      }

      this.translateService.get('PHRASES.' + S(menu.id.toUpperCase()).replaceAll('-', '_').s).subscribe(result => {
        menu.label = result;
      });

      if (menu.items) {
        this.translateMenu(menu.items);
      }
    }

    // Tampilkan menu
    timer(1).subscribe(result => {
      this.app.isMenubarItemTranslated = true;
    });
  }

  /**
   * Fungsi untuk mengambil daftar akses user
   */
  loadPermission() {
    let permissions = this.store.selectSnapshot(AuthState.permissions);
    for (let i = 0; i < permissions.length; i++) {
      this.permissions[permissions[i].slug] = true;
    }
  }

  /**
   * Fungsi untuk mengecek daftar menu berdasarkan akses user
   * @param menues
   * @param parent
   */
  checkMenuPermission(menues: any[], parent?) {
    // Proses item berdasarkan akses user
    for (let menu of menues) {
      // Inisialiasi parent item
      if (parent) {
        menu.parent = parent;
      }

      // Abaikan jika separator
      if (menu.separator) {
        continue;
      }

      if (menu.permission) {
        if (this.permissions[menu.permission]) {
          if (parent) {
            parent.allowed = true;
          }

          menu.allowed = true;
        } else {
          menu.allowed = false;
        }
      }

      if (menu.items) {
        this.checkMenuPermission(menu.items, menu);
      }
    }
  }

  /**
   * Fungsi untuk menyembunyikan menu yang tidak bisa diakses user
   * @param menues
   */
  hideUnallowedMenu(menues: any[]) {
    let separator: any = undefined;

    // Proses item berdasarkan akses user
    for (let menu of menues) {
      // Jika separator, cek juga apakah separator diperlukan
      if (menu.separator) {
        separator = menu;
      }

      if (menu.allowed) {
        menu.visible = true;

        // Tampilkan separator di atasnya
        if (separator) {
          separator.visible = true;
        }
      } else {
        menu.visible = false;
      }

      if (menu.allowed && menu.items) {
        this.hideUnallowedMenu(menu.items);
      }
    }
  }
}
